export type Track = {
  page: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
};

type TrackCases =
  | 'SS_TULINK_PUBLIC_CLICK_SUBMIT_BUTTON'
  | 'SS_TULINK_PUBLIC_SUBMIT_ERROR'
  | 'SS_TULINK_PUBLIC_SUBMIT_SUCCESS'
  | 'SS_TULINK_PUBLIC_SUBMIT';

export const tracks = (track?: TrackCases): Track | undefined => {
  switch (track) {
    case 'SS_TULINK_PUBLIC_CLICK_SUBMIT_BUTTON':
      return {
        page: '/TULINK/ALIAS/',
        eventCategory: '/CHOOSE/PAYMENT/METHOD',
        eventAction: 'CLICK_SUBMIT_BUTTON',
        eventLabel: 'CLICK_SUBMIT_BUTTON',
      };

    case 'SS_TULINK_PUBLIC_SUBMIT':
      return {
        page: '/TULINK/ALIAS/',
        eventCategory: '/CHOOSE/PAYMENT/METHOD',
        eventAction: 'FORM_SUBMIT',
        eventLabel: 'FORM_SUBMIT',
      };

    case 'SS_TULINK_PUBLIC_SUBMIT_SUCCESS':
      return {
        page: '/TULINK/ALIAS/',
        eventCategory: '/CHOOSE/PAYMENT/METHOD',
        eventAction: 'FORM_SUBMIT_SUCCESS',
        eventLabel: 'FORM_SUBMIT_SUCCESS',
      };

    case 'SS_TULINK_PUBLIC_SUBMIT_ERROR':
      return {
        page: '/TULINK/ALIAS/',
        eventCategory: '/CHOOSE/PAYMENT/METHOD',
        eventAction: 'FORM_SUBMIT_ERROR',
        eventLabel: 'FORM_SUBMIT_ERROR',
      };

    default:
      return undefined;
  }
};

function gaTracker(track: TrackCases): void {
  if (!window.meli_ga) {
    return;
  }

  window.meli_ga('send', 'event', tracks(track));
}

function pixelTracker(options: Record<string, unknown>): void {
  if (!window.fbq) {
    return;
  }

  window.fbq('track', 'PageView', options);
}

export { gaTracker, pixelTracker };
