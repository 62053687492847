import type React from 'react';

import { Head } from 'nordic/head';
import { Style } from 'nordic/style';

type WithHeadProps = {
  url: string;
  siteName: string;
  title: string;
  description: string;
  width: number;
  height: number;
  image: string;
  layout?: {
    type: 'default' | 'hidden';
  };
};

function withHead<P>(WrappedComponent: React.ComponentType<P>) {
  // eslint-disable-next-line react/function-component-definition
  return function WithHeadChildComponent(
    props: React.PropsWithChildren<P & Partial<WithHeadProps>>,
  ) {
    const { url, siteName, title, description, image, width, height, layout } =
      props;

    return (
      <>
        <Head>
          <meta property="og:url" content={url} />
          <meta property="og:site_name" content={siteName} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={image} />
          {width && <meta property="og:image:width" content={`${width}`} />}
          {height && <meta property="og:image:height" content={`${height}`} />}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@mercadopago" />
          <meta name="twitter:creator" content="@mercadopago" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={image} />
          <meta httpEquiv="cache-control" content="no-cache" />
          <meta httpEquiv="pragma" content="no-cache" />
          {layout?.type === 'hidden' && (
            <Style>
              {`
              body {
                font-family: "Proxima Nova", -apple-system, Roboto, Arial, sans-serif, sans-serif;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              }
            `}
            </Style>
          )}
        </Head>
        <WrappedComponent {...props} />
      </>
    );
  };
}

export default withHead;
